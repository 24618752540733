/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "pure-react-carousel/dist/react-carousel.es.css"
import "./src/fonts/fonts.css"
import { version } from "./package.json"

console.info("Wersja strony", version)
