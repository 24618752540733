import React from "react"
import { createMuiTheme } from "@material-ui/core"
import { Link as RouterLink } from "gatsby"
import CheckIcon from "@material-ui/icons/Check"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
//import cloudLucentFont from './fonts/Cloud Lucent-Regular.otf'
import "../fonts/fonts.css"
import SmallDot from "../components/icons/SmallDot"

export const CONTAINER_PADDING = 12 // * mui spacing
export const MOBILE_X_PADDING = 4

export const HEADERS_FONT_FAMILY = "Cloud Lucent, serif"

export const YELLOW_BG = "#FEFDF7"
export const PINK_LIGHT_BG = "#FFFCFC"

const theme = (() => {
  const t = createMuiTheme({
    palette: {
      primary: {
        main: "#373733",
      },
      secondary: {
        main: "#E0D5CF",
      },
      background: {
        default: "#ffffff",
      },
      divider: "#DFC7C7",
    },
    typography: {
      fontFamily: ["Montserrat", "Helvetica", "Arial", "sans-serif"].join(", "),
      h1: {
        fontFamily: HEADERS_FONT_FAMILY,
      },
      h2: {
        fontFamily: HEADERS_FONT_FAMILY,
      },
      h3: {
        fontFamily: HEADERS_FONT_FAMILY,
      },
      h4: {
        fontFamily: HEADERS_FONT_FAMILY,
      },
      h5: {
        fontFamily: HEADERS_FONT_FAMILY,
      },
      h6: {
        fontFamily: HEADERS_FONT_FAMILY,
      },
      button: {
        fontSize: "1.125rem",
      },
    },
    props: {
      MuiLink: {
        component: RouterLink,
        underline: "always",
      },
      MuiCheckbox: {
        checkedIcon: <CheckIcon />,
        icon: <CheckBoxOutlineBlankIcon />,
      },
      MuiRadio: {
        checkedIcon: <SmallDot className={"MuiSvgIcon-root"} />, // ta klasa bo był blad ze czasami pojawiał się gigantyczne radio
        icon: <CheckBoxOutlineBlankIcon />,
      },
    },
    shape: 0,
  })

  t.overrides = {
    MuiCssBaseline: {
      "@global": {
        ".underline": {
          textDecoration: "underline",
        },
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        margin: 9,
        color: "rgba(0,0,0,0)",
        backgroundColor: "rgba(255,255,255,1)",
        borderRadius: 8,
        boxShadow: `0px 3px 0px rgba(0, 0, 0, 0.05)`,
        "& .MuiSvgIcon-root": {
          width: "24px",
          height: "24px",
        },
      },
    },
    MuiRadio: {
      root: {
        padding: 0,
        margin: 9,
        color: "rgba(0,0,0,0)",
        backgroundColor: "rgba(255,255,255,1)",
        borderRadius: "50%",
        boxShadow: `0px 3px 0px rgba(0, 0, 0, 0.05)`,
        "& .MuiSvgIcon-root": {
          width: "24px",
          height: "24px",
        },
      },
    },
    MuiButton: {
      root: {
        minHeight: 50,
        fontFamily: HEADERS_FONT_FAMILY,
        textTransform: "capitalize",
      },
      contained: {
        boxShadow: "none",
      },
      containedSecondary: {
        fontFamily: t.typography.fontFamily,
      },
      sizeLarge: {
        padding: t.spacing(1.5, 6),
      },
    },
    MuiLink: {
      root: {
        color: "#3E1821",
        textDecoration: "underline",

        ...[...Array(5).keys()].reduce((acc, index) => {
          acc[`&.MuiTypography-h${index + 1}`] = {
            ...t.typography[`h${index + 1}`],
          }
          return acc
        }, {}),
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottomWidth: 3,
          borderBottomColor: "rgba(0, 0, 0, 0.05)",
        },
        "&:after": {
          borderBottomWidth: 3,
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&:hover": {
          backgroundColor: t.palette.background.paper,
        },
        backgroundColor: t.palette.background.paper,
      },
      focused: {
        backgroundColor: t.palette.background.paper,
        "&:hover": {
          backgroundColor: t.palette.background.paper,
        },
      },
      underline: {
        "&:before": {
          borderBottomWidth: 3,
          borderBottomColor: "rgba(0, 0, 0, 0.05)",
        },
        "&:after": {
          borderBottomWidth: 3,
        },
      },
    },
    MuiInputBase: {
      input: {
        padding: "10px 0 12px",
      },
    },
  }

  return t
})()

export default theme
