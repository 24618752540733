import React from "react"

const SmallDot = props => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" r="5"></circle>
    </svg>
  )
}

export default SmallDot
