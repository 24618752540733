// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-dla-projektantow-js": () => import("./../../../src/pages/dla-projektantow.js" /* webpackChunkName: "component---src-pages-dla-projektantow-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-katalog-js": () => import("./../../../src/pages/katalog.js" /* webpackChunkName: "component---src-pages-katalog-js" */),
  "component---src-pages-logowanie-js": () => import("./../../../src/pages/logowanie.js" /* webpackChunkName: "component---src-pages-logowanie-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-rejestracja-js": () => import("./../../../src/pages/rejestracja.js" /* webpackChunkName: "component---src-pages-rejestracja-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-brand-detail-js": () => import("./../../../src/templates/BrandDetail.js" /* webpackChunkName: "component---src-templates-brand-detail-js" */),
  "component---src-templates-donate-detail-js": () => import("./../../../src/templates/DonateDetail.js" /* webpackChunkName: "component---src-templates-donate-detail-js" */)
}

